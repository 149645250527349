/* eslint-disable @typescript-eslint/no-explicit-any */

const zoneIgnoreOnProperties: {target: unknown, ignoreProperties: string[]}[] = [];

// Disable Zone.js on indexedDB to improve performance
zoneIgnoreOnProperties.push({target: IDBRequest.prototype, ignoreProperties: ['success', 'error']});
zoneIgnoreOnProperties.push({target: IDBOpenDBRequest.prototype, ignoreProperties: ['blocked', 'upgradeneeded']});
zoneIgnoreOnProperties.push({target: IDBDatabase.prototype, ignoreProperties:  ['abort', 'close', 'error', 'versionchange']});
zoneIgnoreOnProperties.push({target: IDBTransaction.prototype, ignoreProperties:  ['abort', 'complete', 'error']});

(window as any).__Zone_ignore_on_properties = zoneIgnoreOnProperties;
